<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <vs-alert color="success" class="mt-3" icon-pack="feather" close-icon="icon-x">
            Total Amount :  {{priceFormat(totalAmount)}}
        </vs-alert>
        <br>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.paymentCod" v-bind:class="[selectedData.id==tr.id ? 'colored' : '']">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
               <vs-td>{{tr.ReferenceCode}}</vs-td>
              <vs-td>{{tr.PaymentMethod}}</vs-td>
              <vs-td>{{priceFormat(tr.PaymentAmount)}}</vs-td>
              <vs-td>{{tr.Notes}}</vs-td>
              
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
    components: {
    },
    props:{
        selected :  Object,
        option : Object
    },
    data() {
        return {
        params: {
            search: "",
            length: 10,
            page: 1,
            order: "asc",
            sort: "payment_code"
        },
        totalAmount: 0,
        Pod:['','Complete','Partial Complete','Partial Redeliver','Failed Complete','Failed Redeliver'],
        PodCanGenerate:[1,2],
        header: [
        {
          text: "Sales Order",
          sortable: false
          // width: '5%'
        },
        {
          text: "Payment Method",
          sortable: false
          // width: '5%'
        },
        {
          text: "Amount",
          sortable: false
        },
        {
          text: "Note",
          sortable: false
        }
      ],
        responseData: {},
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        detail: false,
        selectedData: {},
        validateAll:false,
        validate:[]
        };
    },
    computed:{
        
    },
    watch:{
        
    },
    mounted() {
        this.reloadData(this.params);
        
        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        clickShow(tr) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            // var option = this.option.territory.filter(function(territory)  {
            //       return territory.id == tr.TerritoryID;
            //     })[0]
            this.$emit("changeTab",1,tr.promotion,false)
        },
        closeDetail() {
            this.detail = false;
            this.selectedData = {
                ID : 0
            };
        },
        reloadData(params) {
            this.params = params;
            console.log(params)
            this.$vs.loading();
            this.$http
                .get("api/v1/invoice/cod", {
                params: {
                    search: params.search,
                    length: params.length,
                    page: params.page,
                    order: params.order,
                    sort: params.sort,
                    status: 0,
                }
                })
                .then(
                resp => {
                    if (resp.code == 500) {
                    this.$vs.loading.close();
                    } else if(resp.code == 200) {
                    this.$vs.loading.close();
                    this.data = resp.data.paymentCod;
                    this.responseData = resp.data;
                    this.checkedAll = false;
                    }
                    else {
                    this.$vs.loading.close();
                    }
                    this.totalAmount = resp.data.sumAmount
                }
                );
        }
    }
    };
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
    background:antiquewhite;
    background-color: antiquewhite;
}
</style>