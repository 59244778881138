<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.paymentCod"
              v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']"
            >
              <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
              
              <vs-td>{{tr.ReferenceCode}}</vs-td>
              <vs-td>{{tr.PaymentMethod}}</vs-td>
              <vs-td>{{priceFormat(tr.PaymentAmount)}}</vs-td>
              <vs-td>{{tr.Notes}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "payment_code",
      },
      Pod: [
        "",
        "Complete",
        "Partial Complete",
        "Partial Redeliver",
        "Failed Complete",
        "Failed Redeliver",
      ],
      PodCanGenerate: [1, 2],
      header: [
        {
          text: "Sales Order",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Payment Method",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Note",
          sortable: false,
        },
      ],
      checkedAll: false,
      checked: [],
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedData: {},
      validateAll: false,
      validate: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].ID
  },
  methods: {
    reconcile(){
      this.$vs.loading();
      // var bodyFormData = new FormData();
      // this.checked.push(2)
      // bodyFormData.set("invoice_payment_id", this.checked);
      // bodyFormData.set('TerritoryID', this.territory);
      this.$http
        .post("api/v1/invoice/cod/reconcile")
        .then(resp => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          } else {
            
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.reloadData(this.params);
            this.$vs.loading.close();
          }
          this.checked.splice(0, this.checked.length)
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
        });
    
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      // console.log(this.checkedAll)
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].ID)) {
            //
          } else {
            this.checked.push(this.data[i].ID);
            console.log(this.data[i].ID);
            console.log("this.data[i].ID");
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.data[j].ID), 1);
        }
      }
      // this.checkedAll = null;
      console.log(this.checkedAll);
    },
    clickShow(tr) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      // var option = this.option.territory.filter(function(territory)  {
      //       return territory.ID == tr.TerritoryID;
      //     })[0]
      this.$emit("changeTab", 1, tr.promotion, false);
    },
    closeDetail() {
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/cod", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.paymentCod;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>