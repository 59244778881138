import { render, staticRenderFns } from "./handover.vue?vue&type=template&id=416eef15&scoped=true&"
import script from "./handover.vue?vue&type=script&lang=js&"
export * from "./handover.vue?vue&type=script&lang=js&"
import style0 from "./handover.vue?vue&type=style&index=0&id=416eef15&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416eef15",
  null
  
)

export default component.exports