<template>
  <vx-card :title="title">

   
    <hr>
    <vs-tabs >
        <vs-tab v-if="this.$route.fullPath != '/reconcile-cod'" label="Open">
          <div class="con-tab-ejemplo">
            <open></open>
          </div>
        </vs-tab>
        <vs-tab  label="Handover">
          <div class="con-tab-ejemplo">
            <handover></handover>
          </div>
        </vs-tab>
        <vs-tab  label="Accept">
          <div class="con-tab-ejemplo">
            <accept></accept>
          </div>
        </vs-tab>
        <vs-tab  v-if="this.$route.fullPath == '/reconcile-cod'" label="Reconcile">
          <div class="con-tab-ejemplo">
            <reconcile></reconcile>
          </div>
        </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import open from "./open/open.vue";
import handover from "./handover/handover.vue";
import accept from "./accept/accept.vue";
import reconcile from "./reconcile/reconcile.vue";
export default {
  components:{
    'open' : open,
    'handover':handover,
    'accept':accept,
    'reconcile':reconcile,
  },
  data() {
    return {
      title: '',
      territoryData: [],
      territorySelected: null,
      uploadReady: true,
      salesChannel: {
        options: [],
        selected: null
      },
      salesChannelId: null
    };
  },
  methods: {
    handleDownload() {
      
        this.$vs.loading();
        this.$http
          .get("/api/v1/invoice/reconcile", {
            params: {
              territory_code:1
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream"
            }
          })
          .then(resp => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "invoice-template.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          });
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else {
        this.formData = new FormData();
        this.formData.append("file", file);
        this.$http
          .post("/api/v1/invoice/reconcile", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              var message = "";
              // resp.data.map(function(item, index) {
              //   var total = resp.data.length;
              //   if (index == total - 1) {
              //     message += item.sales_order_code;
              //   } else {
              //     message += item.sales_order_code + ", ";
              //   }
              // });
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.uploadReady = true;
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          });
      }
    },
    getAllDataSalesChannel() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-channel", {
          params: {
            order: "name",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.salesChannel.options = resp.data.records;
            if (this.salesChannel.options.length > 0) {
              this.salesChannel.selected = this.salesChannel.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    }
  },
  mounted() {
    if (this.$route.fullPath == '/reconcile-cod') {
      this.title = 'Reconcile COD'
    } else {
      this.title = 'Payment COD'
    }
    // this.getTerritoryData();
    // this.getAllDataSalesChannel();
  },
  watch: {
    "salesChannel.selected": function(v) {
      this.salesChannelId = v.id;
    }
  }
};
</script>
